import { fromEvent } from 'rxjs'
import { filter, debounceTime } from 'rxjs/operators'

const pageListObj = {
    el: null,
    container: null,
    button: null,
    page: 1,
    perPage: 0,
    hasMore: true,
    btnWrap: null,
    loadingIcon: null,
    url: '',
    filters: null
}

let token

export function PageList() {
    // token = document.querySelector('meta[name=csrf-token]')
    // if (!token) {
    //     return
    // }

    // token = token.content

    const els = document.querySelectorAll('.js-page-list')
    const pageList = Array.from(els)
    pageList.map(createpageList)
    return pageList
}

function createpageList(el) {
    if (!el) {
        return
    }

    const Obj = Object.create(pageListObj)
    init.call(Obj, el)
    return Obj
}

function init(el) {
    this.el = el
    this.container = this.el.querySelector('.js-page-list-container')
    this.button = this.el.querySelector('.js-page-list-btn')
    this.btnWrap = this.el.querySelector('.js-page-list-btn-wrap')
    const filterEls = this.el.querySelectorAll('.js-page-list-filter')
    this.filters = Array.from(filterEls)
    this.url = this.el.dataset.url
    this.perPage = this.el.dataset.show

    if (!this.url) {
        return
    }

    fromEvent(this.el, 'click')
        .pipe(filter((evt) => evt.target.classList.contains('js-page-list-btn')))
        .subscribe(() => {
            loadPage.call(this)
        })

    // fromEvent(this.el, 'keyup')
    //     .pipe(
    //         filter((evt) => evt.target.name === 'search'),
    //         debounceTime(250)
    //     )
    //     .subscribe((evt) => {
    //         if (evt.target.value.length > 3 || evt.target.value.length === 0) {
    //             loadPage.call(this, true)
    //         }
    //     })

    fromEvent(this.el, 'change')
        .pipe(
            filter((evt) => evt.target.classList.contains('js-page-list-filter')),
            debounceTime(250)
        )
        .subscribe(() => {
            loadPage.call(this, true)
        })
}

function loadPage(clear = false) {
    if (!this.hasMore && !clear) {
        return
    }

    if (clear) {
        this.page = 0
    }

    this.button.classList.add('u-btn--loading')

    this.page++
    const formData = new FormData()
    formData.append('page', this.page)
    formData.append('perPage', this.perPage)

    this.filters.forEach((el) => {
        const filterKey = el.name
        const filterValue = el.value
        formData.append(filterKey, filterValue)
    })

    const headers = {
        'X-Requested-With': 'XMLHttpRequest'
        // 'csrf-token': token
    }

    return fetch(this.url, {
        method: 'POST',
        body: formData,
        headers
    })
        .then((data) => data.json())
        .then((data) => {
            let {
                html,
                pagination: { hasNextPage, page }
            } = data
            if (clear) {
                clearPage.call(this)
            }

            this.hasMore = hasNextPage
            if (page === this.page) {
                addPage.call(this, html)
            }

            if (!this.hasMore) {
                this.button.disabled = true
            } else {
                this.button.disabled = false
            }
        })
        .finally(() => {
            this.button.classList.remove('u-btn--loading')
        })
}

function clearPage() {
    this.container.innerHTML = ''
}

function addPage(html) {
    const div = document.createElement('div')
    div.innerHTML = html
    Array.from(div.children).forEach((el) => {
        this.container.appendChild(el)
    })

    if (!this.hasMore) {
        this.btnWrap.style.display = 'none'
    } else {
        this.btnWrap.style.display = 'flex'
    }
}
