export function SliderInit() {
    const genericSliderEls = document.querySelectorAll('.js-slider')
    const bargateWaySliderEl = document.querySelectorAll('.js-bargate-way-slider')

    if (genericSliderEls.length || bargateWaySliderEl.length) {
        import('../slider/index')
            .then((m) => m.Slider)
            .then((Slider) => {
                genericSlider(Slider, genericSliderEls)
                bargateWaySlider(Slider, bargateWaySliderEl)
            })
    }
}

function bargateWaySlider(Slider, els) {
    if (!els || !els.length) {
        return
    }

    const sliders = Array.from(els)
    window.bargateWaySliders = sliders.map((el) => {
        return Slider(el, {
            options: {
                slidesToShow: 1,
                slidesToScroll: 1,
                horizontalSlide: true,
                arrows: true,
                autoplay: false,
                referenceArrows: true,
                isInfinite: true,
                dots: false,
                disabled: true,
                responsive: {
                    '768px': {
                        disabled: false
                    },
                    '1325px': {
                        slidesToShow: 2
                    },
                    '1750px': {
                        slidesToShow: 3
                    }
                }
            },
            classes: {
                slider: 'u-slider',
                viewport: 'u-slider__viewport',
                track: 'u-slider__track',
                slide: 'u-slider__slide',
                slideClone: 'u-slider__slide-clone'
            }
        })
    })
}

function genericSlider(Slider, els) {
    if (!els || !els.length) {
        return
    }

    const sliders = Array.from(els)
    window.sliders = sliders.map((el) => {
        let { infinite, slidesToShow } = el.dataset
        let isInfinite = infinite !== 'false'
        let responsive = {}

        if (+slidesToShow === 3) {
            slidesToShow = 1

            responsive = {
                '1325px': {
                    slidesToShow: 3
                }
            }
        }

        return Slider(el, {
            options: {
                slidesToShow: 1,
                slidesToScroll: 1,
                horizontalSlide: true,
                arrows: true,
                autoplay: false,
                referenceArrows: true,
                isInfinite,
                dots: false,
                responsive
            },
            classes: {
                slider: 'u-slider',
                viewport: 'u-slider__viewport',
                track: 'u-slider__track',
                slide: 'u-slider__slide',
                slideClone: 'u-slider__slide-clone'
            }
        })
    })
}
